<template>
   <div class="mianbox">
        <div class="screenbox">
            <div class="screen">
                <div class="screenbox-one">
                    <el-form ref="form" :model="form" label-width="80px" class="searchbox_left">
                        <div class="leftbox">
                            <label class="biaoqian">
                                位置搜索：
                            </label>
                            <div class="locationbox" >
                                <div class="sousbox">
                                <el-cascader v-if="!editstate"
                                ref="cascaderAddr"
                                :options="baselandareaoptions"
                                @change="newhandleChen"
                                v-model="newvalue"
                                size="small"
                                placeholder="请筛选区域："
                                ></el-cascader>
                                </div>
                                <div class="sousbox">
                                <el-cascader v-if="editstate"
                                ref="cascaderAddr"
                                :props="prop"
                                @change="handleChen"
                                v-model="value"
                                size="small"
                                placeholder="请筛选区域："
                                ></el-cascader>
                                </div>
                            </div> 
                            <div class="left_one">
                                <el-input
                                    placeholder="请输入详细位置："
                                    v-model="poikey"
                                    clearable
                                    size="small">
                                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            </div>
                            <div class="left_three">
                                <el-button type="primary" size="small" plain  @click="onSubmit">查询</el-button> 
                                <el-button type="primary" size="small" plain  @click="datalistshow?datalistshow=false:datalistshow=true">列表</el-button> 
                            </div> 
                        </div>
                    </el-form>
                    <div @click="zksq">
                        <i :class="this.show?'el-icon-caret-top':'el-icon-caret-bottom'" style="font-size:2.4rem;color:#999999"></i>
                    </div>
                </div>
                <el-collapse-transition>
                    <div v-show="show" class="conditionbox">
                        <!--来源筛选-->
                        <div class="conditionbox_one">
                            <label class="biaoqian">
                                数据来源：
                            </label>
                            <ul class="sourcebox" style="margin:0;padding:0">
                                <li  v-for="(item, index) in menuList" :key="index" :class="{ active_li: item.id === menuselect }" @click="menuchange(item)">
                                    {{item.name}}
                                </li>
                            </ul>
                        </div>
                        <!--用途筛选-->
                        <div class="conditionbox_two">
                            <label class="biaoqian">
                                用途类型：
                            </label>
                            <ul class="sourcebox" style="margin:0;padding:0">
                                <li v-for="(item, index) in userType" :key="index" :class="{ active_li: item.id === useselect }" @click="usechange(item)">
                                    {{item.name}}
                                </li>
                            </ul>
                        </div>
                        <!--时间筛选-->
                        <div class="conditionbox_three" v-if="editstate">
                            <label class="biaoqian">
                                时间：
                            </label>
                            <div class="timebox">
                                <el-date-picker
                                    v-model="timevalue"
                                    type="daterange"
                                    size="small"
                                    @change='timechange()'
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </div>
                        </div>
                        <!--范围筛选-->
                        <div class="conditionbox_four">
                            <label class="biaoqian" v-if='editstate'>
                                距离范围：
                            </label>
                            <div class="Radiobox" v-if='editstate'>
                                 <el-radio-group v-model="Range">
                                    <el-radio :label="item.label"  v-for="(item, index) in Rangelist" :key="index" @change="handleChange"> 
                                        {{item.name}}米</el-radio>
                                </el-radio-group>
                            </div>
                            <!-- <div v-if='!editstate'>
                                <label class="biaoqian">
                                    基准地价日：
                                </label>
                                <el-select 
                                    @change="dateChen"
                                    v-model="options"
                                >
                                    <el-option v-for="item in option" :key="item.id" :label=item.value :value="item.id"></el-option>
                                </el-select>
                            </div> -->
                        </div>
                    </div>
                </el-collapse-transition>
            </div>
        </div>
        <div class="mapview">
            <!-- 1.创建地图容器元素 -->
		    <div class="map" id="mapView">
            </div>
        </div> 
        <!--列表-->
        <div class="datalistbox" v-if="datalistshow">
            <div class="datalist_headbox">
                <div class="head_left">
                    数据列表
                </div>
                <div class="head_right" @click="closedatalist">
                    <i class="el-icon-close"></i>
                </div>
            </div>
            <div class="datalist_bodybox">
                <el-table  v-loading="listloading" :data="datalist" height="39.7rem" size="mini" border style="width: 100%;">
                    <el-table-column v-for="item in datalisttitles"
                        :prop="item.prop"
                        :label="item.label" >
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作" >
                    <template slot-scope="scope">
                        <el-button @click="listDataShow(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                    </el-table-column> 
                </el-table>
                <el-pagination
                @current-change="currentTabList"
                small
                layout="prev, pager, next" 
                :current-page="newUrl.page"
                :total="newUrl.pagenum">
                </el-pagination>
            </div>
        </div>
        <!--向左弹窗-->
        <el-drawer
        title="数据详情"
        :visible.sync="detailstc"
        direction="rtl"
        size="47%">
            <div class="miaosubox">
                <el-descriptions
                v-for="(item,index) in basicdata"
                class="margin-top"
                :column="1"
                labelStyle="width:180px;"
                border
                >
                    <el-descriptions-item v-if="item.name!='lat'&&item.name!='lng'&&item.name!='type'">
                        <template slot="label"> 
                        {{item.name}}
                        </template>
                        <label @click="showMapAdd2(item.name=='标的物'||(item.name=='名称'&&item.type!=1)||(item.name=='地址'&&item.type!=1),basicdata)" :class="item.name=='标的物'||(item.name=='名称'&&item.type!=1)||(item.name=='地址'&&item.type!=1)?'lablevual':''"><i v-if="item.name=='标的物'||(item.name=='名称'&&item.type!=1)||(item.name=='地址'&&item.type!=1)" class="el-icon-map-location"></i>{{item.value}}</label>
                    </el-descriptions-item> 
                </el-descriptions>
            </div>
            <div class="miaosubox" v-if="listzs">
                <el-table
                    :data="tableData"
                    border
                    style="width: 100%"
                    height="508">
                    <el-table-column 
                    prop="Location"
                    label="名称"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="HouseType"
                    label="户型"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="Area"
                    label="面积(m²)"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="Price"
                    label="单价(元/m²)"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    prop="TotalPrice"
                    label="总价(万元)"
                    width="120">
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-drawer
                        title="地图位置"
                        :append-to-body="true" 
                        :visible.sync="innerDrawer2">
                        <div style="height: 100%; width:100%" id="mapdeiView2"></div>
                    </el-drawer>
        </el-drawer>
        <!--详细弹窗弹窗-->
        <el-drawer
        title="二手房明细详情"
        :visible.sync="newdetailstc"
        direction="rtl"
        size="47%">
            <div class="miaosubox">
                <el-descriptions
                v-for="(item,index) in newbasicdata"
                class="margin-top"
                :column="1"
                labelStyle="width:180px;"
                border
                >
                    <el-descriptions-item v-if="item.name!='lat'&&item.name!='lng'">
                        <template slot="label"> 
                        {{item.name}}
                        </template>
                        <label @click="showMapAdd(item.name=='地址',newbasicdata)" :class="item.name=='地址'?'lablevual':''"><i v-if="item.name=='地址'" class="el-icon-map-location"></i>{{item.value}}</label>
                       
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <el-drawer
                title="地图位置"
                :append-to-body="true" 
                :visible.sync="innerDrawer">
                <div style="height: 100%; width:100%" id="mapdeiView"></div>
            </el-drawer>
        </el-drawer>
</div>
</template>
<script>

import { type } from "os";
import {instance} from "../../apis/apis";
import TMap from '../../../util/initTxMap';
import { gcj_wgs_encrypts2,wgs_gcj_encrypts } from '../../../util/map_help';
const maplevel1 = require('@/assets/imgs/1.png')
const maplevel2 = require('@/assets/imgs/2.png')
const maplevel3 = require('@/assets/imgs/3.png')
const maplevel4 = require('@/assets/imgs/4.png')
const maplevel5 = require('@/assets/imgs/5.png')
const maplevel6 = require('@/assets/imgs/6.png')
const maplevel7 = require('@/assets/imgs/7.png')
const maplevel8 = require('@/assets/imgs/8.png')
const maplevel9 = require('@/assets/imgs/9.png')
const maplevel10 = require('@/assets/imgs/10.png')
	export default {
		name: "mapview",
		props: {
            zoom: {
				type: Number,
				default: 11
			}
		},
		data() {
			return {
                datalisttitles:[
                    {
                    prop:'date',
                    label:'日期',
                    },
                    {
                    prop:'name',
                    label:'名称',
                    },{
                    prop:'address',
                    label:'地址',
                    },
                ],
                listloading:false,
                innerDrawer:false,
                innerDrawer2:false,
                txdeimap:null,
                markerdei:null,
                tmap1:null,
                txdeimap2:null,
                markerdei2:null,
                tmap2:null,
                datalist: [],
                datalistshow:false,
                tMap: null, // 腾讯地图实例
                TXMap: null, // 腾讯地图原型 
                markerLayer:null,
                domOverlaylist:null,
                polygonlist:null,//面
                iseventClick:false,//是否添加点击事件
                tableData:[],
                basicdata:[],
                newbasicdata:[],
                divisioncode:'',
                map: null,
                poikey:'',
                listzs:false,
                detailstc:false,
                newdetailstc:false,
				markers: [],
                value: [],
                templatitude: 0,  //临时上点经纬度
				templongitude: 0,
                newvalue:'510100|510100',
                options:'0',
                option:[
                    {id:'0',value:'2020-01-01'}
                ],
                prop: {
                    lazy: true,
                    lazyLoad :(node, resolve)=> {
                      this.getCity(node).then((res) => {
                        var list =  res.data.response;
                            var nodes = [];
                            list.forEach(item=>{       
                                const obj={
                                    value:item.Code,
                                    label:item.Name,
                                    leaf: node.level >= 1, 
                                }   
                                nodes.push(obj)                                                    
                            });
                            // resolve 节点返回
                            resolve(nodes);
                        });  
                    }
                },
                newprop: {
                    lazy: true,
                    lazyLoad :(node, resolve)=> {
                      this.getCity(node).then((res) => {
                        var list =  res.data.response;
                            var nodes = [];
                            list.forEach(item=>{       
                                const obj={
                                    value:item.Code,
                                    label:item.Name,
                                    leaf: node.level >= 0, 
                                }   
                                nodes.push(obj)                                                    
                            });
                            // resolve 节点返回
                            resolve(nodes);
                        });  
                    }
                },
                 //范围选择
                 Range:'1',
                 Rangelist:[],
                 qtRangelist:[
                    {
                        label:'1',
                        name:'300'
                    },{
                        label:'2',
                        name:'500'
                    } 
                ],
                ldRangelist:[
                    {
                        label:'1',
                        name:'500'
                    },{
                        label:'2',
                        name:'1000'
                    } ,{
                        label:'3',
                        name:'3000'
                    } 
                ],
                //土地范围选择
                tdRangelist:[
                    {
                        label:'1',
                        name:'3000'
                    },{
                        label:'2',
                        name:'5000'
                    },{
                        label:'3',
                        name:'10000'
                    }
                ],
                //列表展示的值
                tableList:[
                    {
                        prop:'number',
                        label:'项目编号'
                    },
                    {
                        prop:'name',
                        label:'项目名称'
                    },
                    {
                        prop:'source',
                        label:'数据来源'
                    },
                    {
                        prop:'purpose',
                        label:'用途类型'
                    },
                    {
                        prop:'price',
                        label:'价格（元）'
                    },
                    {
                        prop:'time',
                        label:'时间'
                    }
                ],
                //时间范围
                timevalue: '',
                //数据来源
                menuList:[
                    {
                        id:1,
                        name:'二手房' 
                    },
                    {
                        id:2,
                        name:'司法拍卖' 
                    },
                    {
                        id:3,
                        name:'新房'
                    },
                    {
                        id:4,
                        name:'基准地价'
                    },
                    {
                        id:5,
                        name:'土地出让'
                    },
                    //{
                    //    id:7,
                    //    name:'租赁'
                    //},
                    {
                        id:6,
                        name:'历史项目数据'
                    } 
                ],
                editstate:true,
                menuselect:1,
                userType:[
                {
                        id:null,
                        name:'不限'
                    },
                    {
                        id:1,
                        name:'住宅'
                    },
                    {
                        id:2,
                        name:'商业'
                    },
                    {
                        id:3,
                        name:'工业'
                    },
                    {
                        id:4,
                        name:'办公'
                    }, 
                ],
                //土拍用途类型：
                tpType:[
                    {
                        id:null,
                        name:'不限'
                    },
                    {
                        id:1,
                        name:'住宅用地'
                    },
                    {
                        id:2,
                        name:'商业用地'
                    },
                    {
                        id:3,
                        name:'工业用地'
                    },
                    {
                        id:4,
                        name:'公共管理与公共服务用地'
                    },
                    {
                        id:12,
                        name:'其他'
                    }              
                ],
                 //二手房用途类型：
                 efType:[
                    {
                        id:null,
                        name:'不限'
                    },
                    {
                        id:1,
                        name:'住宅'
                    },
                    {
                        id:2,
                        name:'商业'
                    },
                    {
                        id:3,
                        name:'工业'
                    },
                    {
                        id:4,
                        name:'办公'
                    },  
                ],
                 //新房用途类型：
                 xfType:[
                    {
                        id:null,
                        name:'不限'
                    },
                    {
                        id:1,
                        name:'住宅'
                    },
                    {
                        id:2,
                        name:'商业'
                    },
                    {
                        id:3,
                        name:'别墅'
                    },
                    {
                        id:4,
                        name:'办公'
                    },
                    {
                        id:5,
                        name:'洋房'
                    },
                    {
                        id:6,
                        name:'公寓'
                    }      
                ],
                 //基准地价用途类型：
                 jzType:[
                    {
                        id:null,
                        name:'住宅用地'
                    },
                    {
                        id:1,
                        name:'商业用地'
                    },
                    {
                        id:2,
                        name:'工业用地'
                    },
                    {
                        id:3,
                        name:'公共管理与公共服务用地'
                    },             
                ],
                 //司法拍卖用途类型：
                 sfType:[
                    {
                        id:null,
                        name:'不限'
                    },
                    {
                        id:1,
                        name:'住宅'
                    },
                    {
                        id:2,
                        name:'商业'
                    },
                    {
                        id:3,
                        name:'工业'
                    }, 
                    {
                        id:4,
                        name:'车位'
                    },
                    {
                        id:5,
                        name:'其他'
                    }            
                ],
                 //租赁类型：
                 rentType:[
                    {
                        id:null,
                        name:'不限'
                    },
                    {
                        id:1,
                        name:'住宅'
                    },
                    {
                        id:2,
                        name:'商业'
                    },
                    {
                        id:3,
                        name:'工业'
                    },
                    {
                        id:4,
                        name:'办公'
                    },  
                    {
                        id:5,
                        name:'土地'
                    },  
                    {
                        id:6,
                        name:'保障房'
                    },  
                    {
                        id:7,
                        name:'普通商业'
                    },  
                    {
                        id:8,
                        name:'商品房'
                    },  
                    {
                        id:9,
                        name:'地下商业'
                    },  
                    {
                        id:10,
                        name:'仓储'
                    },  
                    {
                        id:11,
                        name:'车位'
                    },  
                    {
                        id:12,
                        name:'公共'
                    },  
                    {
                        id:13,
                        name:'教育'
                    }, 
                    {
                        id:14,
                        name:'廉租房'
                    }, 
                    {
                        id:15,
                        name:'旅馆'
                    }, 
                    {
                        id:16,
                        name:'市场'
                    }, 
                    {
                        id:17,
                        name:'娱乐'
                    }, 
                    {
                        id:18,
                        name:'其他'
                    }, 
                ],
                 //资产类型：
                 zcType:[
                    {
                        id:null,
                        name:'不限'
                    },
                    {
                        id: 1,
                        name: '房产-住宅'
                    }, {
                        id: 2,
                        name: '房产-商业'
                    },{
                        id: 3,
                        name: '房产-办公'
                    },{
                        id: 4,
                        name: '房产-车位'
                    },{
                        id: 1002,
                        name: '房产-工业'
                    },{
                        id: 1003,
                        name: '房产-在建工程'
                    },{
                        id: 1004,
                        name: '土地-住宅'
                    },{
                        id: 1005,
                        name: '土地-工业'
                    },{
                        id: 1006,
                        name: '股权'
                    },{
                        id: 1007,
                        name: '采矿权'
                    },{
                        id: 1008,
                        name: '原酒'
                    },{
                        id: 1009,
                        name: '林权'
                    },{
                        id: 1010,
                        name: '房产-酒店'
                    },{
                        id: 1011,
                        name: '土地-商业'
                    },{
                        id: 1012,
                        label: '股票'
                    }           
                ],
                useselect:null,
                //搜索关键词
                form: {
                    name: '',
                    location:'',
                },
                //接口关键词
                newUrl:{
                    type:1,//数据来源
                    mappoint:'|',//坐标
                    useType:null,//用途
                    kil:300,//距离
                    stime:null,
                    etime:null,
                    dizhi:'',
                    mappointtx:null,
                    page:1,
                    size:10,
                    pagenum:0,
                    pagerul:''
                },
                //接口关键词
                ennewUrl:{
                    type:4,//数据来源
                    mappoint:'510100|510100',//坐标
                    useType:1,//用途
                    stime:'2020/01/01',
                    etime:'2020/12/30',
                    dizhi:'成都市'
                },
                //展开收起
                show:true,
                baselandareaoptions:[{
                    value: '510000',
                    label: '成都市',
                    children:[{
                        value: '510100|510100',
                        label: '市辖区',
                    },{
                        value: '510100|510112',
                        label: '龙泉驿区',
                    }
                    ,{
                        value: '510114|510114',
                        label: '新都区',
                    }
                    ,{
                        value: '510100|510124',
                        label: '郫都区',
                    }
                    ,{
                        value: '510100|510121',
                        label: '金堂县',
                    }
                    ,{
                        value: '510100|510182',
                        label: '彭州市',
                    },{
                        value: '510100|510132',
                        label: '新津区',
                    }
                    ,{
                        value: '512000|512081',
                        label: '简阳市',
                    }
                    ,{
                        value: '510100|510129',
                        label: '大邑县',
                    },{
                        value: '510100|510184',
                        label: '崇州市',
                    },{
                        value: '510100|510181',
                        label: '都江堰市',
                    },{
                        value: '510100|510111',
                        label: '东部新区',
                    }]
                },{
                    value: '510700',
                    label: '绵阳市',
                    children:[{
                        value: '510700|510722',
                        label: '三台县',
                    }]
                },{
                    value: '511100|511100',
                    label: '乐山市',
                },{
                    value: '510600|510600',
                    label: '德阳市',
                },{
                    value: '511600|511600',
                    label: '广安市',
                },{
                    value: '511300|511300',
                    label: '南充市',
                },{
                    value: '512000|512000',
                    label: '资阳市',
                    children:[{
                        value: '512000|512000',
                        label: '市辖区',
                    },{
                        value: '512000|512021',
                        label: '安岳县',
                    }]
                },{
                    value: '511500|511500',
                    label: '宜宾市',
                },{
                    value: '511000|511000',
                    label: '内江市',
                },{
                    value: '510300|510300',
                    label: '自贡市',
                },{
                    value: '513400',
                    label: '凉山彝族自治州',
                    children:[{
                        value: '513400|513401',
                        label: '西昌市',
                    }]
                }]
            }
		}, 
		mounted() {  
            this.$nextTick(() => {
                // 禁用右键
                document.oncontextmenu = new Function("event.returnValue=false");
                // 禁用选择
                document.onselectstart = new Function("event.returnValue=false");
            }); 
            var id=0;
            if(this.$route.params.id>0){
                id = Number(this.$route.params.id) 
            } 
			this.onload(id);
            
            this.Rangelist=this.qtRangelist

            if(id>0){
                this.menuchange({id:id},1) 
            } 
		},
		methods: {
            //查看详细地图
            showMapAdd(isshow,m){
                if(!isshow){
                    return
                }
                this.innerDrawer=true;
                var lat = 0;
                        var lng = 0;
                        m.forEach(item => {
                            if(item.name=='lat'){
                                lat = item.value;
                            }
                            if(item.name=='lng'){
                                lng = item.value;
                            }
                        });
                   
                        var pointnew = wgs_gcj_encrypts(parseFloat(lat), parseFloat(lng)); 

                if(this.txdeimap==null){ 
                    TMap.init().then((TMap) => { 
                        this.tmap1 = TMap
                     
                        this.txdeimap = new TMap.Map("mapdeiView", {
                            center: new TMap.LatLng(pointnew.lat,pointnew.lon), //设置地图中心点坐标
                            zoom: 15, //设置地图缩放级别
                            viewMode: "2D",
                            doubleClickZoom:false,
                        });    
                       
                        if(this.markerdei!=null){
                            this.markerdei.setMap(null);
                            this.markerdei=null;
                        } 
                        this.markerdei = new TMap.MultiMarker({
                            map: this.txdeimap,
                            styles: {
                            // 点标记样式
                            marker: new TMap.MarkerStyle({
                                width: 20, // 样式宽
                                height: 30, // 样式高
                                anchor: { x: 10, y: 30 }, // 描点位置
                            }),
                            },
                            geometries: [
                            // 点标记数据数组
                            {
                                // 标记位置(纬度，经度，高度)
                                position: new TMap.LatLng(pointnew.lat, pointnew.lon),
                                id: 'marker1',
                            },
                            ],
                        }); 
                    }); 

                   
                }else{
                    this.txdeimap.setCenter(new this.tmap1.LatLng(pointnew.lat,pointnew.lon)); 
                    if(this.markerdei!=null){
                            this.markerdei.setMap(null);
                            this.markerdei=null;
                        } 
                        this.markerdei = new this.tmap1.MultiMarker({
                            map: this.txdeimap,
                            styles: {
                            // 点标记样式
                            marker: new this.tmap1.MarkerStyle({
                                width: 20, // 样式宽
                                height: 30, // 样式高
                                anchor: { x: 10, y: 30 }, // 描点位置
                            }),
                            },
                            geometries: [
                            // 点标记数据数组
                            {
                                // 标记位置(纬度，经度，高度)
                                position: new this.tmap1.LatLng(pointnew.lat, pointnew.lon),
                                id: 'marker1',
                            },
                            ],
                        }); 
                }
            },
            showMapAdd2(isshow,m){
                if(!isshow){
                    return
                }
                this.innerDrawer2=true;

                var lat = 0;
                var lng = 0;
                var type = 0;
                        m.forEach(item => {
                            if(item.name=='lat'){
                                lat = item.value;
                            }
                            if(item.name=='lng'){
                                lng = item.value;
                            }
                            if(item.name=='type'){
                                type = item.value;
                            }
                        });

               
                var pointnew = {lat:parseFloat(lat),lon:parseFloat(lng)}
                if(type !=3&&type !=5){
                   pointnew = wgs_gcj_encrypts(parseFloat(lat), parseFloat(lng));
                } 

                  

                if(this.txdeimap2==null){ 
                    TMap.init().then((TMap) => { 
                        
                        this.tmap2 = TMap
                     
                        this.txdeimap2 = new TMap.Map("mapdeiView2", {
                            center: new TMap.LatLng(pointnew.lat,pointnew.lon), //设置地图中心点坐标
                            zoom: 15, //设置地图缩放级别
                            viewMode: "2D",
                            doubleClickZoom:false,
                        });    
                       
                        if(this.markerdei2!=null){
                            this.markerdei2.setMap(null);
                            this.markerdei2=null;
                        } 
                        this.markerdei2 = new TMap.MultiMarker({
                            map: this.txdeimap2,
                            styles: {
                            // 点标记样式
                            marker: new TMap.MarkerStyle({
                                width: 20, // 样式宽
                                height: 30, // 样式高
                                anchor: { x: 10, y: 30 }, // 描点位置
                            }),
                            },
                            geometries: [
                            // 点标记数据数组
                            {
                                // 标记位置(纬度，经度，高度)
                                position: new TMap.LatLng(pointnew.lat, pointnew.lon),
                                id: 'marker2',
                            },
                            ],
                        }); 
                    });  
                }else{
                    this.txdeimap2.setCenter(new this.tmap2.LatLng(pointnew.lat,pointnew.lon)); 
                    if(this.markerdei2!=null){
                            this.markerdei2.setMap(null);
                            this.markerdei2=null;
                        } 
                        this.markerdei2 = new this.tmap2.MultiMarker({
                            map: this.txdeimap2,
                            styles: {
                            // 点标记样式
                            marker: new this.tmap2.MarkerStyle({
                                width: 20, // 样式宽
                                height: 30, // 样式高
                                anchor: { x: 10, y: 30 }, // 描点位置
                            }),
                            },
                            geometries: [
                            // 点标记数据数组
                            {
                                // 标记位置(纬度，经度，高度)
                                position: new this.tmap2.LatLng(pointnew.lat, pointnew.lon),
                                id: 'marker2',
                            },
                            ],
                        });
                }
            },
            //关闭数据列表弹窗
            closedatalist(){
                this.datalistshow=false
            },
            //基准地价日改变
            dateChen(value){
                this.ennewUrl.stime=this.option[value].value.substr(0,4)+"/01/01";
                this.ennewUrl.etime=this.option[value].value.substr(0,4)+"/12/30";
                this.jihzundijia(this.ennewUrl)
            },
            //基准地价地区改变
            newhandleChen(){
                var thsAreaCode = this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels
                var thsArealeb = this.$refs.cascaderAddr.getCheckedNodes()[0].value
              
                var cityname = thsAreaCode[0];
                var countyname = thsAreaCode[1];
                if(countyname=='金堂县'){
                    countyname='金堂县淮口街道'
                }
                this.ennewUrl.dizhi="四川省"+cityname+countyname;
                this.ennewUrl.mappoint=thsArealeb;
                this.jihzundijia(this.ennewUrl)
                this.searaddress(cityname, countyname==""||countyname==null?cityname:countyname,"",4,12)
            },
            //地址改变
            handleChen() {
                var _this = this;
                var thsAreaCode = _this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels 
                _this.newUrl.dizhi=thsAreaCode[0]+thsAreaCode[1]; 
                _this.divisioncode = _this.$refs.cascaderAddr.getCheckedNodes()[0].value;
            },
            async getCity(code){  
                var ncode = '510000000';
                if(code.level==0){
                    ncode='510000000';
                }else{
                    ncode=code.value;
                }
                return await instance.get('/Map/GetRegionArea?cord=' +ncode); 
            },
            //时间改变
            timechange(){ 
                console.log(this.timevalue)
                if(this.timevalue !=null){
                    var st = new Date(this.timevalue[0]).toLocaleString();
                    var et = new Date(this.timevalue[1]).toLocaleString();
                    this.newUrl.stime=st
                    this.newUrl.etime=et
                    this.initMap(this.newUrl) 
                }
                else{
                    this.newUrl.stime=null
                    this.newUrl.etime=null
                }
            },
            //数据来源点击改变
            menuchange(item,v){
                this.menuselect=item.id
                //二手房
                if(this.menuselect==1){
                    this.editstate=true
                    this.userType=this.efType
                    this.newUrl.type=this.menuselect
                    this.useselect=null
                    this.Rangelist=this.qtRangelist
                    this.Rangelist.forEach(i => {
                        if(this.Range==i.label){
                            this.newUrl.kil=i.name
                     } 
                    });
                    this.newUrl.useType=null
                    if(v!=1){
                        this.addMapCilick();
                        this.initMap(this.newUrl)
                    } 
                }
                if(this.menuselect==2){
                    this.editstate=true
                    this.userType=this.sfType
                    this.newUrl.type=this.menuselect
                    this.useselect=null
                    this.Rangelist=this.ldRangelist
                    this.Rangelist.forEach(i => {
                        if(this.Range==i.label){
                            this.newUrl.kil=i.name
                     } 
                    });
                    this.newUrl.useType=null
                    if(v!=1){
                        this.addMapCilick();
                        this.initMap(this.newUrl)
                    }  
                    console.log(this.Rangelist)
                }
                if(this.menuselect==3){
                    this.editstate=true
                    this.userType=this.xfType
                    this.newUrl.type=this.menuselect
                    this.Rangelist=this.ldRangelist
                    this.Rangelist.forEach(i => {
                        if(this.Range==i.label){
                            this.newUrl.kil=i.name
                     } 
                    });
                    this.useselect=null
                    this.newUrl.useType=null
                    if(v!=1){
                        this.addMapCilick();
                        this.initMap(this.newUrl)
                    }  
                }
                if(this.menuselect==4){
                    this.editstate=false
                    this.userType=this.jzType
                    this.newUrl.type=this.menuselect
                    this.ennewUrl.type=this.menuselect
                    this.useselect=null
                    this.ennewUrl.useType=null 
                    if(v!=1){
                        this.delMapCilick();
                        this.jihzundijia(this.ennewUrl)
                    } 
                }
                //土地
                if(this.menuselect==5){
                    this.editstate=true
                    this.userType=this.tpType
                    this.newUrl.type=this.menuselect
                    this.useselect=null
                    this.Rangelist=this.tdRangelist
                    this.Rangelist.forEach(i => {
                        if(this.Range==i.label){
                            this.newUrl.kil=i.name
                     } 
                    });
                    this.newUrl.useType=null
                    if(v!=1){
                        this.addMapCilick();
                        this.initMap(this.newUrl)
                    } 
                  
                }
                if(this.menuselect==6){
                    this.editstate=true
                    this.userType=this.zcType
                    this.newUrl.type=this.menuselect
                    this.Rangelist=this.ldRangelist
                    this.Rangelist.forEach(i => {
                        if(this.Range==i.label){
                            this.newUrl.kil=i.name
                     } 
                    }); 
                    this.useselect=null
                    this.newUrl.useType=null
                    if(v!=1){
                        this.addMapCilick();
                        this.initMap(this.newUrl)
                    } 
                  
                }
                if(this.menuselect==7){
                    this.editstate=true
                    this.userType=this.rentType
                    this.newUrl.type=this.menuselect
                    this.Rangelist=this.ldRangelist
                    this.Rangelist.forEach(i => {
                        if(this.Range==i.label){
                            this.newUrl.kil=i.name
                     } 
                    }); 
                    this.useselect=null
                    this.newUrl.useType=null
                    if(v!=1){
                        this.addMapCilick();
                        this.initMap(this.newUrl)
                    } 
                  
                }
                
            },
            //用途类型点击改变
            usechange(item){
                this.useselect=item.id
                if(this.menuselect==2 || this.menuselect==3){   
                    if(item.id !=null){
                        this.newUrl.useType= item.name;
                        this.initMap(this.newUrl)
                    }else{
                        this.newUrl.useType= null;
                        this.initMap(this.newUrl)
                    }
                }else if(this.menuselect==4){ 
                    this.ennewUrl.useType= this.useselect
                    this.jihzundijia(this.ennewUrl)
                }else{
                    this.newUrl.useType= this.useselect  
                    this.initMap(this.newUrl)
                }
            },
            //附近距离
            handleChange(item){
                this.Range=item  
                this.Rangelist.forEach(i => {
                    if(this.Range==i.label){
                        this.newUrl.kil=i.name
                     } 
                }); 
                this.initMap(this.newUrl)        
            },
            //点击行
            handleClick(row) {
                this.newdetailstc=true; 
                this.newbasicdata= [
                    {name:'类别',value:'二手房明细信息'},
                    {name:'名称',value:row.Name==null? "/": row.Name},
                    {name:'地址',value:row.Location==null? "/": row.Location},
                    {name:'成交价格(元/m²)',value:row.Price==null? "/": row.Price},
                    {name:'成交总价(万元)',value:row.TotalPrice==null? "/": row.TotalPrice},
                    {name:'建筑面积(㎡)',value:row.Area==null? "/": row.Area}, 
                    {name:'房屋户型',value:row.HouseType==null? "/": row.HouseType},
                    {name:'房屋用途',value:this.getHouseUse(row.HousePurpose)},
                    {name:'户型结构',value:row.HouseStructure==null? "/": row.HouseStructure}, 
                    {name:'装修情况',value:row.Decoration==null? "/": row.Decoration},
                    {name:'所在楼层',value:row.Floor==null? "/": row.Floor},
                    {name:'总楼层',value:row.TotalFloor==null? "/": row.TotalFloor},
                    {name:'交易时间',value:row.AcceptanceDate==null? "/": row.AcceptanceDate.substr(0,10)},
                    {name:'产权所属',value:row.Ownership==null? "/": row.Ownership},
                    {name:'lat',value:row.Lat},
                    {name:'lng',value:row.Lng},
               ];   
             },
            //土拍明细
            tpInfo(id){
                instance.get('/Map/GetLandViewModel?id='+id).then(res=>{
                    if(res.data.success){
                        var list=res.data.response; 
                        this.basicdata= [
                            {name:'类别',value:'土地出让信息'},
                            {name:'宗地编号',value:list.Name==null? "/":list.Name},
                            {name:'地址',value:list.Location==null? "/":list.Location},
                            {name:'土地面积(m²)',value:list.LandArea==null? "/":list.LandArea},
                            {name:'土地面积(亩)',value:list.LandArea*0.0015},
                            {name:'规划容积率',value:list.PlanningPlotRatio==null? "/":list.PlanningPlotRatio},
                            {name:'最大容积率',value:(list.MaxPlanningPlotRatio).toFixed(1)},
                            {name:'楼面地价(元/㎡)',value:list.LandPrice2==null? "/":list.LandPrice2},
                            {name:'成交价格(万元/亩)',value:list.LandPrice==null? "/":list.LandPrice},
                            {name:'交易时间',value:list.SellTime==null? "/":list.SellTime.substr(0,10)},
                            {name:'交易结果',value:list.Status},
                            {name:'土地用途',value:list.PurposeText==null? "/":list.PurposeText},
                            {name:'兼容用途',value:list.CompatiblePurposeTypeID==null? "/":list.CompatiblePurposeTypeID},
                            {name:'规划建筑高度',value:list.PlanningBuildingHight==null? "/":list.PlanningBuildingHight},
                            {name:'规划建筑密度',value:list.PlanningBuildingCoverage==null? "/":list.PlanningBuildingCoverage},
                            {name:'规划建筑面积(m²)',value:list.PlanningHouseArea==null? "/":list.PlanningHouseArea},
                            {name:'其中规划商业建筑面积(m²)',value:list.PlaningForCommerceArea==null? "/":list.PlaningForCommerceArea},
                            {name:'其中规划住宅建筑面积(m²)',value:list.PlaninForResideArea==null? "/":list.PlaninForResideArea},
                            {name:'规划绿地率',value:list.PlanningGreenRatio==null? "/":list.PlanningGreenRatio},
                            {name:'其他规划',value:list.OtherPlanning==null? "/":list.OtherPlanning},
                            {name:'投资强度',value:list.PlanningInvestment==null? "/":list.PlanningInvestment},
                            {name:'土地详细用途',value:list.OtherPlanning==null? "/":list.OtherPlanning},
                            {name:'持证准用面积',value:list.AcceptanceArea==null? "/":list.AcceptanceArea},
                            {name:'产业要求',value:list.IndustryPlan==null? "/":list.IndustryPlan},
                            {name:'是否监测范围',value:list.InMonitor==false ? '否':'是'},
                            {name:'监测区段',value:list.Section==null? "/":list.Section},
                            {name:'开发补充指标',value:list.DevelopmentSupplement==null? "/":list.DevelopmentSupplement},
                            {name:'规划自持',value:list.SelfSupport==null? "/":list.SelfSupport},
                            {name:'商业上限比例',value:list.Commercial==null? "/":(list.Commercial*100)+'%'}, 
                            {name:'lat',value:list.Lat},
                            {name:'lng',value:list.Lng},
                            {name:'type',value:5},
                        ];                                     
                    }
                });

            },//二手房小区明细
            esfInfo(id){
                instance.get('/Map/GetCommunityDetails?id='+id).then(res=>{
                    console.log(res)
                    if(res.data.success){
                        var list=res.data.response; 
                        this.basicdata= [
                            {name:'类别',value:'二手房小区信息'},
                            {name:'名称',value:list.Name==null? "/": list.Name,type:1},
                            {name:'地址',value:list.Location==null? "/": list.Location,type:1},
                            {name:'房屋用途',value:this.getHouseUse(list.PurposeTypeID)},
                            {name:'平均单价(元/m²)',value:list.BasePrice==null? "/": list.BasePrice},
                        ];
                        if(list.secondList!=null&&list.secondList.length > 0){
                            this.listzs=true;
                            this.tableData=list.secondList;
                        }else{
                            this.tableData=[];
                        } 
                    }
                });
            },//新房明细
            xfInfo(id){
                instance.get('/Map/GetNewHouseId?id='+id).then(res=>{
                    if(res.data.success){
                        var list=res.data.response; 
                        this.basicdata= [
                            {name:'类别',value:'新房明细信息'},
                            {name:'名称',value:list.Name==null? "/": list.Name},
                            {name:'地址',value:list.Location==null? "/": list.Location},
                            {name:'用户数',value:list.TotalnewHouse==null? "/": list.TotalnewHouse},
                            {name:'建筑面积(m²)',value:list.BuildingArea==null? "/": list.BuildingArea}, 
                            {name:'预售时间',value:list.PreSaleCodeDate!=null?list.PreSaleCodeDate.substr(0,10):"/"},
                            {name:'预售单价(元/m²)',value:this.setNewHousePrice(list.MaxUnitPrice,list.MinUnitPrice)},
                            {name:'物业情况',value:list.Property==null? "/": list.Property},
                            {name:'物理管理费',value:list.PropertyManagementFee==null? "/": list.PropertyManagementFee}, 
                            {name:'开发商',value:list.DevelopCompany==null? "/": list.DevelopCompany},
                            {name:'使用年限',value:list.PropertyRightYear==null? "/": list.PropertyRightYear},
                            {name:'所在栋数',value:list.TotalBuilding==null? "/": list.TotalBuilding},
                            {name:'车位数',value:list.TotalParking==null? "/": list.TotalParking},
                            {name:'总面积(m²)',value:list.ToTalArea==null? "/": list.ToTalArea},                    
                            {name:'建筑类型',value:list.BuildingType==null? "/": list.BuildingType}, 
                            {name:'建筑结构',value:list.BuildingStructure==null? "/": list.BuildingStructure}, 
                            {name:'房屋用途',value:list.FirstFormat==null? "/": list.FirstFormat},
                            {name:'lat',value:list.Lat},
                            {name:'lng',value:list.Lng},
                            {name:'type',value:3},
                        ];                              
                    }
                });
            },//基准地价明细
            jzdjInfo(id){
                instance.get('/Map/GetLandgradeId?id='+id).then(res=>{
                    if(res.data.success){
                        var list=res.data.response; 
                        this.basicdata= [
                            {name:'类别',value:'基准地价明细信息'},
                            {name:'土地级别',value:list.Name},
                            {name:'基准地价(元/m²)',value:list.BaseLandPriceSquare==null? '/':list.BaseLandPriceSquare},
                            {name:'基准地价(万元/亩)',value:list.BaseLandPrice},
                            {name:'楼面地价(元/m²)',value:list.BaseLandPriceSquare==null? '/':list.BaseLandPriceSquare},
                            {name:'基准日',value:list.BaseDate!=null?list.BaseDate.substr(0,10):"/"},
                            {name:'容积率',value:(list.VolumeRatio).toFixed(1)},
                            {name:'开发程度',value:list.DevelopmentDegree==""?'/': list.DevelopmentDegree}
                        ];                                  
                    }
                });
            },//司法拍卖明细
            sfInfo(id){
                instance.get('/Map/GetJudicialAuctionId?id='+id).then(res=>{
                    if(res.data.success){
                        var list=res.data.response; 
                        this.basicdata= [
                            {name:'类别',value:'司法拍卖明细信息'},
                            {name:'标的物',value:list.Address==null? "/":list.Address},
                            {name:'拍卖类型',value:list.TypeName==null? "/":list.TypeName},
                            {name:'面积(m²)',value:list.Area==null? "/":list.Area},
                            {name:'拍卖阶段',value:list.AuctionStage==null? "/":list.AuctionStage},
                            {name:'起拍价(元)',value:list.Starting==null? "/":list.Starting},
                            {name:'保证金(元)',value:list.Bond==null? "/":list.Bond},
                            {name:'加价幅度',value:list.MarkUp==null? "/":list.MarkUp},
                            {name:'评估价(元)',value:list.Evaluation==null? "/":list.Evaluation},
                            {name:'拍卖结果',value:list.State==null? "/":list.State},
                            {name:'成交价(元)',value:list.Transaction==null? "/":list.Transaction},
                            {name:'成交单价(元/m²)',value:(list.Transaction/list.Area).toFixed(2)},
                            {name:'成交日期',value:list.EndTime ==null? "/":list.EndTime.substr(0,10)},
                            {name:'建成年代',value:list.Year==null? "/":list.Year},
                            {name:'起拍折扣率',value:list.BuildingAreaDec==null? "/":list.BuildingAreaDec},
                            {name:'数据来源',value:list.Source==null? "/":list.Source},
                            {name:'lat',value:list.Lat},
                            {name:'lng',value:list.Lng},
                            {name:'type',value:2},
                        ];                                            
                    }
                });
            },//资产详情明细
            zcInfo(id){
                instance.get('/AssetsInfo/GetInfo?id='+id).then(res=>{
                    if(res.data.success){
                        var list=res.data.response; 
                        this.basicdata= [
                            {name:'类别',value:'资产明细信息'},
                            {name:'资产名称',value:list.Name},
                            {name:'资产地址',value:list.LocationName},
                            {name:'资产类型',value:this.zctypelist(list.TypeId)},
                            {name:'处置方式',value:this.cztype(list.DisposeTypeId)},
                            {name:'变现折扣(%)',value:list.Realization==null? '/':list.Realization},
                            {name:'变现价值(万元)',value:list.RealizableValue==null? '/':list.RealizableValue},
                            {name:'变现税费(万元)',value:list.RealizableTax==null? '/':list.RealizableTax},
                            {name:'清算价值(万元)',value:list.LiquidateValue==null? '/':list.LiquidateValue},
                            {name:'评估面积(m²)',value:list.ComputeArea==null? '/':list.ComputeArea},
                            {name:'评估单价(元/m²)',value:list.UnitPrice==null? '/':list.UnitPrice},
                            {name:'评估总价(万元)',value:list.ComputePrice==null? '/':list.ComputePrice},
                            {name:'评估时间',value:list.ModifyTime.substr(0,10)},
                            {name:'瑕疵',value:list.Flaw==null ? '/' :list.Flaw}
                        ];                                   
                    }
                });
            }, 
            rentInfo(id){
                instance.get('/Map/GetRentsId?id='+id).then(res=>{
                    if(res.data.success){
                        var list=res.data.response; 
                        this.basicdata= [
                            {name:'类别',value:'租赁详细信息'},
                            {name:'资产名称',value:list.AssetName},
                            {name:'资产所在地区',value:list.ProvinceName+list.CityName+list.DivisionName},
                            {name:'资产详细地址',value:list.Address},
                            {name:'所属楼盘',value:list.CommunityName},
                            {name:'类型',value:list.Type},
                            {name:'资产类型',value:list.AssetType},
                            {name:'面积',value:list.Area},
                            {name:'起始总价(年)',value:list.StaTPriYear},
                            {name:'起始总价(月)',value:list.StaTPriMon},
                            {name:'起始单价(平方米/月)',value:list.StaTPriMon},
                            {name:'竞买保证金',value:list.Bail},
                            {name:'交易结果',value:list.Result},
                            {name:'成交总价(年)',value:list.TotalPriceYear},
                            {name:'成交总价(月)',value:list.TotalPriceMon},
                            {name:'成交单价(平方米/月)',value:list.PriceMon},
                            {name:'业态',value:list.BusinessForms},
                            {name:'价款支付方式',value:list.PaymentMode},
                            {name:'租赁价格说明',value:list.RentExplain},
                            {name:'租赁期限',value:list.TermLease}, 
                            {name:'所在层数',value:list.Floor},
                            {name:'评估价(万元)',value:list.EvaluatedPrice},
                            {name:'是否联合出租',value:list.IsUniteRent},
                            {name:'是否允许联合出租',value:list.IsAllowUniteRent},
                            {name:'是否涉及优先权',value:list.IsPriority},
                            {name:'资产包简介',value:list.AssetsIntr},
                            {name:'标的简介',value:list.Intr},
                            {name:'标的特别提示',value:list.Tip},
                            {name:'出租方企业类型',value:list.ComType},
                            {name:'出租方住所/注册地址',value:list.RentAddress},
                            {name:'出租方法定代表人/负责人',value:list.Principal},
                            {name:'出租方注册资本(万元)',value:list.RegCapital},
                            {name:'出租方所属行业',value:list.Trade},
                            {name:'出租方经营规模',value:list.Scale},
                            {name:'出租方国资监管机构',value:list.Regulator},
                            {name:'出租方国家出资企业或主管部门名称',value:list.ComDepartment},
                            {name:'履约保证金处置方式',value:list.BailDisposal},
                            {name:'与出租相关的其他条件',value:list.OtherCondition},
                            {name:'承租方需承诺事项',value:list.TenantMatter},
                            {name:'承租方资格条件',value:list.TenantCondition},
                            {name:'重大事项及其他披露内容',value:list.Matter}, 
                        ];                                   
                    }
                });
            }, 
            cztype(tid){
                if(tid==1){
                    return "债权转让";
                }else if(tid==2){
                    return "司法处置";
                }else if(tid==3){
                    return "以物抵债";
                }else{
                    return "未知";
                }
            },
            zctypelist(tid){
                if(tid==1){
                    return "房产-住宅";
                }else if(tid==2){
                    return "房产-商业";
                }else if(tid==3){
                    return "房产-办公";
                }else if(tid==4){
                    return "房产-车位";
                }else if(tid==1002){
                    return "房产-工业";
                }else if(tid==1003){
                    return "房产-在建工程";
                }else if(tid==1004){
                    return "土地-住宅";
                }else if(tid==1005){
                    return "土地-工业";
                }else if(tid==1006){
                    return "股权";
                }else if(tid==1007){
                    return "采矿权";
                }else if(tid==1008){
                    return "原酒";
                }else if(tid==1009){
                    return "林权";
                }else if(tid==1010){
                    return "房产-酒店";
                }else if(tid==1011){
                    return "土地-商业";
                }else if(tid==1012){
                    return "股票"
                }else{
                    return "其他";
                }
            },  
            //查询按钮
            onSubmit() {                
                if(this.newUrl.type!=4){
                    
                     if(this.newUrl.dizhi==null||this.newUrl.dizhi==''){
                        this.$message({
                            message: "请选择区域",
                            type: "error",
                        });
                        return
                    } 
                    if(this.newUrl.type==5){
                        if(this.poikey==null||this.poikey==''){
                            this.newUrl.page =1;
                            this.newUrl.pagerul='/Map/GetLandPageList?type='+(this.newUrl.useType!=null?this.newUrl.useType:"0")
                            +'&stime='+(this.newUrl.stime!=null?this.newUrl.stime:"")+'&etime='+(this.newUrl.etime!=null?this.newUrl.etime:"")+'&division='
                            +(this.divisioncode!=null?this.divisioncode:"");
                            this.gettablist();
                        }else{
                            this.searaddress(this.newUrl.dizhi,"",this.poikey,this.newUrl.type,17)
                        }
                    }else{
                        if(this.poikey==null||this.poikey==''){
                        this.$message({
                            message: "请输入详细地址",
                            type: "error",
                        });
                        return
                    } 
                    this.searaddress(this.newUrl.dizhi,"",this.poikey,this.newUrl.type,17)
                    }
                    
                }

            },
            setNewHousePrice(maxprice,minprice){
                if(maxprice==null && minprice==null){
                    return "/"
                }else if(maxprice!=null && minprice==null){
                    return maxprice;
                }else if(maxprice==null && minprice!=null){
                    return minprice;
                }else{
                    return minprice +"-"+maxprice;
                }
            },
            getHouseUse(tid){
                if(tid==1){
                    return "住宅";
                }else if(tid==2){
                    return "商业";
                }else if(tid==3){
                    return "工业"
                }else if(tid==4){
                    return "办公"
                }else{
                    return "未知"
                }
            },
            zksq(){
                if(this.show==false){
                    this.show=true
                }else{
                    this.show=false
                }
            },
            searchResult(result){         
                if(result.getStatus() == 0){
			        this.map.panTo(result.getLocationPoint(),14);
                    this.templatitude=result.getLocationPoint().lat;
				    this.templongitude=result.getLocationPoint().lng;
                    this.newUrl.dizhi=result.location.keyWord
                    this.newUrl.mappoint=result.getLocationPoint().lng+"|"+result.getLocationPoint().lat;
                    this.initMap(this.newUrl);
		        }
            },
            newsearchResult(result){      
                if(result.getStatus() == 0){
                    // 7.添加定位点
                    this.map.panTo(result.getLocationPoint(),this.map.getZoom());     
		        }
            },
            //求多边形重心点
            calzxCenter(mPoints) {
                var area = 0.0;//多边形面积
                var Gx = 0.0, Gy = 0.0;// 重心的x、y
                for (let i = 1; i <= mPoints.length; i++) {
                    var iLat = mPoints[i % mPoints.length].lat;
                    var iLng = mPoints[i % mPoints.length].lng;
                    var nextLat = mPoints[i - 1].lat;
                    var nextLng = mPoints[i - 1].lng;
                    var temp = (iLat * nextLng - iLng * nextLat) / 2.0;
                    area += temp;
                    Gy += temp * (iLat + nextLat) / 3.0;
                    Gx += temp * (iLng + nextLng) / 3.0;
                }
                Gx = Gx / area;
                Gy = Gy / area;
                return { 'lng': Gx, 'lat': Gy};
            },
            //双击地图添加点位
            addMapCilick(){ 
                if(this.iseventClick==false){
                    this.iseventClick = true;
                    this.tMap.on("dblclick", this.dbeventClick) 
                }
               
            },  
            delMapCilick(){
                if(this.iseventClick){
                    this.iseventClick =false;
                    this.tMap.off("dblclick", this.dbeventClick) 
                } 
            },
            dbeventClick(e){  
                this.show=false
                var _this=this; 
                _this.templatitude=e.latLng.lat;
                _this.templongitude=e.latLng.lng;
                _this.newUrl.mappoint=e.latLng.lng+'|'+e.latLng.lat
                _this.newUrl.mappointtx = e.latLng; 
                _this.initMap(_this.newUrl);
            },
            onload(v){ 
                if(this.tMap==null){ 
                    TMap.init().then((TMap) => {
                        this.TXMap = TMap;
                        this.tMap = new TMap.Map("mapView", {
                            center: new TMap.LatLng(30.6584274,104.0657339), //设置地图中心点坐标
                            zoom: 15, //设置地图缩放级别
                            viewMode: "2D",
                            doubleClickZoom:false,
                        });  
                        var control = this.tMap.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
                        control.setPosition(TMap.constants.CONTROL_POSITION.BOTTOM_RIGHT);
                        this.tMap.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
                        this.addMapCilick(); 
                        if(v==4){
                            this.ennewUrl.type=4 
                            this.ennewUrl.useType=null 
                            this.jihzundijia(this.ennewUrl) 
                        }
                    }); 
                }
            }, 
            jihzundijia(ennewUrl){ 
                var _this = this;
                //清除所有点位
                 if(_this.polygonlist==null){
                    _this.polygonlist =[]
                 }else{
                    _this.polygonlist.forEach(item=>{
                        item.setMap(null); 
                    })
                    _this.polygonlist =[]
                 }
                 if(_this.domOverlaylist==null){
                    _this.domOverlaylist = []
                }else{
                    _this.domOverlaylist.forEach(item=>{ 
                        item.setMap(null)
                    })
                    _this.domOverlaylist = []
                } 
                if (_this.markerLayer != null) {
                    _this.markerLayer.setGeometries([]);

                }  
                  
                    _this.markerLayer = new _this.TXMap.MultiMarker({
                        map: _this.tMap, geometries: [],
                        styles: {
                            "level1": new _this.TXMap.MarkerStyle({
                                "src": maplevel1
                            }),
                            "level2": new _this.TXMap.MarkerStyle({
                                "src": maplevel2
                            }),
                            "level3": new _this.TXMap.MarkerStyle({
                                "src": maplevel3
                            }),
                            "level4": new _this.TXMap.MarkerStyle({
                                "src": maplevel4
                            }),
                            "level5": new _this.TXMap.MarkerStyle({
                                "src": maplevel5
                            }),
                            "level6": new _this.TXMap.MarkerStyle({
                                "src": maplevel6
                            }),
                            "level7": new _this.TXMap.MarkerStyle({
                                "src": maplevel7
                            }),
                            "level8": new _this.TXMap.MarkerStyle({
                                "src": maplevel8
                            }),    
                            "level9": new _this.TXMap.MarkerStyle({
                                "src": maplevel9
                            }),  
                            "level10": new _this.TXMap.MarkerStyle({
                                "src": maplevel10
                            }),      
                        }
                    });
               
                if(ennewUrl.useType == null){
                    var uul='/Map/GetList?type='+ennewUrl.type+'&mappoint='+ennewUrl.mappoint+'&stime='+ennewUrl.stime+'&etime='+ennewUrl.etime+'&ytlx=1';
                }else{
                    var lx=ennewUrl.useType+1;
                    var uul='/Map/GetList?type='+ennewUrl.type+'&mappoint='+ennewUrl.mappoint+'&stime='+ennewUrl.stime+'&etime='+ennewUrl.etime+'&ytlx='+lx;
                }
                instance.get(uul).then((res) => { 
                    var list = res.data.response; 
                    var levelColorArray = new Array("rgba(255,126,112,0.5)", "rgba(255,211,1,0.5)", "rgba(224,163,246,0.5)", "rgba(215,255,154,0.5)", "rgba(255,150,66,0.5)", 
                    "rgba(189,227,253,0.5)", "rgba(130,139,255,0.5)", "rgba(0,108,255,0.5)", "rgba(81,255,96,0.5)"); 
                    var borderColorArray = new Array("#ff7e70", "#ffd301", "#e0a3f6", "#d7ff9a", "#ff9642", "#bde3fd", "#828bff", "#006cff", "#51ff60"); 
                    if(list!=null&&list.length>0){ 
                        list.forEach((item,index)=>{
                            var jsarr3 = JSON.parse(item.Lng);  
                            var fillColor = levelColorArray[item.Level-1];
                            var fillborcolor = borderColorArray[item.Level-1];
                            if(item.Level==101){
                                fillColor ="rgba(81,255,96,0.5)";
                                fillborcolor ="#51ff60";
                            }else if(item.Level==102){
                                fillColor="rgba(128,128,128,0.9)"
                                fillborcolor ="#808080";
                            } else{
                                var latarr = ['30.66341704031658', '30.792211781217794', '30.782083970601512', '30.653294679436225'];
                                //创建图片对象
                                if (this.calzxCenter(jsarr3).lat > 0 && this.calzxCenter(jsarr3).lat != latarr[0] && this.calzxCenter(jsarr3).lat != latarr[1]
                                    && this.calzxCenter(jsarr3).lat != latarr[2] && this.calzxCenter(jsarr3).lat != latarr[3]) {
                                    var zxd = this.calzxCenter(jsarr3); 
                                     // 绘制地点标注
                                    _this.markerLayer.add({
                                        id: "marker" + index,  
                                        position: new _this.TXMap.LatLng(zxd.lat, zxd.lng), 
                                        styleId:"level" + item.Level,
                                    });  
                                } 
                            }

                            var path = [];
                            jsarr3.forEach(m=>{
                                var pointnew = wgs_gcj_encrypts(parseFloat(m.lat), parseFloat(m.lng));
                                path.push(new _this.TXMap.LatLng(pointnew.lat, pointnew.lon))
                            })

                            // 初始化polygon
                            var polygon = new _this.TXMap.MultiPolygon({
                                id: 'polygon-layer' + index, // 图层id
                                map: this.tMap, // 显示多边形图层的底图
                                styles: {
                                    // 多边形的相关样式
                                    polygon: new _this.TXMap.PolygonStyle({
                                        color: fillColor, // 面填充色
                                        showBorder: false, // 是否显示拔起面的边线
                                        borderColor: fillborcolor, // 边线颜色
                                    }),
                                },
                                geometries: [
                                        {
                                            id: 'polygon' + index, // 多边形图形数据的标志信息
                                            styleId: 'polygon', // 样式id
                                            paths: path, // 多边形的位置信息
                                            properties: {
                                                // 多边形的属性数据
                                                title: 'polygon',
                                            },
                                        },
                                ],
                            });
  
                            polygon.on('click', ()=>{
                                this.addMouseoverHandler(4,item.Id);
                            });
                            _this.polygonlist.push(polygon) 
                        });  
                    }               
                });
            },
			initMap(newUrl) {
                var _this = this; 

                if(_this.polygonlist==null){
                    _this.polygonlist =[]
                 }else{
                    _this.polygonlist.forEach(item=>{
                        item.setMap(null); 
                    })
                    _this.polygonlist =[]
                 }

                 if(_this.domOverlaylist==null){
                    _this.domOverlaylist = []
                }else{
                    _this.domOverlaylist.forEach(item=>{ 
                        item.setMap(null)
                    })
                    _this.domOverlaylist = []
                }

                if(_this.newUrl.mappointtx==null||_this.newUrl.mappointtx==''){
                    return
                }
               
                 
                if(_this.markerLayer!=null){
                        _this.markerLayer.setGeometries([]);
                    } 
                    _this.markerLayer = new _this.TXMap.MultiMarker({ map: _this.tMap, geometries: [], 
                            styles: {
                                "highlight": new _this.TXMap.MarkerStyle({
                                    "src": "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png", 
                                        }),
                                    },
                            });
                    // 绘制地点标注
                    _this.markerLayer.add({
                        id: 101,  
                        position: _this.newUrl.mappointtx, 
                        styleId:"highlight"
                    }); 
 
                if(newUrl.type !=4 && newUrl.type !=6 ){   
                    var strpoint = newUrl.mappoint;
                    if(newUrl.type !=3&&newUrl.type !=5&&newUrl.type !=7){
                        var pointselarr = newUrl.mappoint.split("|") 
                        var pointnewsel = gcj_wgs_encrypts2(parseFloat(pointselarr[1]),parseFloat(pointselarr[0])) 
                        strpoint = pointnewsel.lon+"|"+ pointnewsel.lat;
                    } 
                    newUrl.page =1;
                    if(newUrl.stime == null && newUrl.useType == null ){
                        var urll='/Map/GetList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil;    
                        newUrl.pagerul='/Map/GetAssetDataList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil;        
                    }else if(newUrl.stime != null && newUrl.useType != null ){
                        var urll='/Map/GetList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil+'&stime='+newUrl.stime+'&etime='+newUrl.etime+'&ytlx='+newUrl.useType;
                        newUrl.pagerul='/Map/GetAssetDataList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil+'&stime='+newUrl.stime+'&etime='+newUrl.etime+'&ytlx='+newUrl.useType;
                    }else if(newUrl.stime == null && newUrl.useType != null ){
                        var urll='/Map/GetList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil+'&ytlx='+newUrl.useType;   
                        newUrl.pagerul='/Map/GetAssetDataList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil+'&ytlx='+newUrl.useType;   
                    }else if(newUrl.stime != null && newUrl.useType == null){
                        var urll='/Map/GetList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil+'&stime='+newUrl.stime+'&etime='+newUrl.etime;
                        newUrl.pagerul='/Map/GetAssetDataList?type='+newUrl.type+'&mappoint='+strpoint+'&kil='+newUrl.kil+'&stime='+newUrl.stime+'&etime='+newUrl.etime;
                    }  
                    instance.get(urll).then((res) => { 
                        console.log(res)
                        var list = res.data.response;   
                        if(list!=null&&list.length>0){ 
                            list.forEach((item, index)=>{ 
                                var pointnew = {lat:parseFloat(item.Lat),lon:parseFloat(item.Lng)}
                                if(newUrl.type !=3&&newUrl.type !=5&&newUrl.type !=7){
                                    pointnew = wgs_gcj_encrypts(parseFloat(item.Lat), parseFloat(item.Lng));
                                } 

                                var latlng = new _this.TXMap.LatLng(pointnew.lat, pointnew.lon);
                                var lableName=item.Name.substr(0,8);
                                // if(this.newUrl.type==5 ||this.newUrl.type==2 ){
                                //     lableName=item.Name.substr(0,8)
                                // }else if(this.newUrl.type==3 ||this.newUrl.type==1 || this.newUrl.type==6){
                                //     lableName=item.Name.substr(0,8)
                                // }
                                
                               var domover = _this.svgDOMOverlay({
                                    map:this.tMap,
                                    position: latlng,
                                    data: lableName, 
                                    typeid:item.TypeId,
                                    id:item.Id,
                                })
                                _this.domOverlaylist.push(domover) 
                            });    
                            //修改地图中心点
                            _this.tMap.setCenter(_this.newUrl.mappointtx)
                            if(newUrl.type==5){
                                _this.tMap.setZoom(14)
                            }else{
                                _this.tMap.setZoom(17)
                            }
                          
                        }              
                    });
                    this.gettablist();
                }else if(newUrl.type ==6){
                    if(newUrl.stime == null && newUrl.useType == null ){
                        var nurll='/AssetsInfo/GetPtList?mappoint='+newUrl.mappoint+'&kil='+newUrl.kil;           
                    }else if(newUrl.stime != null && newUrl.useType != null ){
                        var nurll='/AssetsInfo/GetPtList?mappoint='+newUrl.mappoint+'&kil='+newUrl.kil+'&stime='+newUrl.stime+'&etime='+newUrl.etime+'&ytlx='+newUrl.useType;
                    }else if(newUrl.stime == null && newUrl.useType != null ){
                        var nurll='/AssetsInfo/GetPtList?mappoint='+newUrl.mappoint+'&kil='+newUrl.kil+'&ytlx='+newUrl.useType;   
                    }else if(newUrl.stime != null && newUrl.useType == null){
                        var nurll='/AssetsInfo/GetPtList?mappoint='+newUrl.mappoint+'&kil='+newUrl.kil+'&stime='+newUrl.stime+'&etime='+newUrl.etime;
                    } 
                    instance.get(nurll).then((res) => { 
                        var list = res.data.response;   
                        if(list!=null&&list.length>0){
                            list.forEach(item=>{ 
                                var latlng = new _this.TXMap.LatLng(item.Lat, item.Lng);
                                var lableName=item.Name.substr(0,10)

                                var domover = _this.svgDOMOverlay({
                                    map:this.tMap,
                                    position: latlng,
                                    data: lableName, 
                                    typeid:item.TypeId,
                                    id:item.Id,
                                })
                                _this.domOverlaylist.push(domover)         
                            });    
                        }              
                    });
                }   
			},
            //点击覆盖物上的监听事件
            addMouseoverHandler(typeid,id){
                console.log(typeid,id)
		       var _this = this;
                    if(typeid==1){//二手房数据明细 
                        _this.detailstc=true;
                        _this.esfInfo(id)
                    }else if(typeid==2){//司法数据明细  
                        _this.detailstc=true;
                        _this.listzs=false;
                        _this.sfInfo(id)
                    }else if(typeid==3){//新房数据明细  
                        _this.detailstc=true;
                        _this.listzs=false;
                        _this.xfInfo(id)
                    }else if(typeid==4){//基准地价数据明细
                        _this.detailstc=true;
                        _this.listzs=false;
                        _this.jzdjInfo(id)
                    }else if(typeid==5){//土拍数据明细   
                        _this.detailstc=true;
                        _this.listzs=false;
                        _this.tpInfo(id)
                    }else if(typeid==6){//资产数据明细
                        _this.detailstc=true;
                        _this.listzs=false;
                        _this.zcInfo(id)
                    }else if(typeid==7){//租赁
                        _this.detailstc=true;
                        _this.listzs=false;
                        _this.rentInfo(id)
                    }	 
            }, 
             
            svgDOMOverlay(item){
                var _this =this;
                var SVG_NS = 'http://www.w3.org/2000/svg';
                // 自定义覆盖物 - 继承DOMOverlay
               
                var Donut=function(options){
                    _this.TXMap.DOMOverlay.call(this, options);
                }
                Donut.prototype = new _this.TXMap.DOMOverlay();

                // 初始化
                Donut.prototype.onInit = function(options) {
                    this.position = options.position;
                    this.data = options.data; 
                };

                // 销毁时需解绑事件监听
                Donut.prototype.onDestroy = function() {
                    if (this.onClick) {
                        this.dom.removeEventListener(this.onClick);
                    }
                };

                // 创建DOM元素，返回一个DOMElement，使用this.dom可以获取到这个元素
                Donut.prototype.createDOM = function() {
                    let svg = document.createElementNS(SVG_NS, 'svg');
                    svg.setAttribute('version', '1.1');
 
                    svg.setAttribute('viewBox', [0, 0, 100, 56].join(' '));
                    svg.setAttribute('width', 100);
                    svg.setAttribute('height', 56);
                    svg.style.cssText = 'position:absolute;top:0px;left:0px;';//border: 1px solid steelblue

  
                    let donut = createDonut(this.data);
                    svg.appendChild(donut);
                
                    // click事件监听
                    this.onClick = () => {
                        // DOMOverlay继承自EventEmitter，可以使用emit触发事件
                        this.emit('click');
                    };
                    // pc端注册click事件，移动端注册touchend事件
                    svg.addEventListener('click', this.onClick);
                    return svg;
                };

                // 更新DOM元素，在地图移动/缩放后执行
                Donut.prototype.updateDOM = function() {
                    if (!this.map) {
                        return;
                    } 
                    // 经纬度坐标转容器像素坐标
                    let pixel = this.map.projectToContainer(this.position);

                    // 图中心点对齐经纬度坐标点
                    let left = pixel.getX() - this.dom.clientWidth / 2 + 'px';
                    let top = pixel.getY() - this.dom.clientHeight / 2 + 'px';
                    this.dom.style.transform = `translate(${left}, ${top})`;
                };

                // 使用SVG创建图形
                function createDonut(data) { 
                    let group = document.createElementNS(SVG_NS, "g"); 

                    //绘制椭圆形
                    let rectShape = document.createElementNS(SVG_NS, 'rect');
                    rectShape.setAttribute('x', '0');
                    rectShape.setAttribute('y', '0'); 
                    rectShape.setAttribute('rx', '14'); 
                    rectShape.setAttribute('ry', '14'); 
                    rectShape.setAttribute('width', '100');
                    rectShape.setAttribute('height', '28');
                    rectShape.setAttribute('style', 'fill:#3072f6;');
                    group.appendChild(rectShape);

                    //绘制三角形
                    let polygonShape = document.createElementNS(SVG_NS, 'polygon');
                    polygonShape.setAttribute('points', '44,27 50,34 56,27'); 
                    polygonShape.setAttribute('style', 'fill:#3072f6;');
                    group.appendChild(polygonShape);

                    // 绘制文字
                    let textShape = document.createElementNS(SVG_NS, 'text');
                    textShape.setAttribute('x', 50);
                    textShape.setAttribute('y', 18);
                    textShape.setAttribute('font-size', 12);
                    textShape.setAttribute('text-anchor', 'middle');
                    textShape.setAttribute('fill', '#FFFFFF');
                    textShape.innerHTML = data;
                    group.appendChild(textShape);
 
                    return group;
                }
 
                var dountmodel = new Donut(item);
                dountmodel.on('click', () => { 
                    this.addMouseoverHandler(item.typeid,item.id)
				});
                return dountmodel
            },
            //地图输入框搜索
            searaddress(citykey,countykey,addkey,type,zoom) {   
                //根据输入框获取对应经纬度坐标 
                var _this = this;
                if (citykey != "") {
                    var search = new this.TXMap.service.Search({ pageSize: 1 }); // 新建一个地点搜索类 
                    // 在地图显示范围内以给定的关键字搜索地点
                    search.searchRegion({
                        keyword: citykey + countykey + addkey,
                        cityName: citykey,
                    }).then((result) => {
                        var cenpoi = null; 
                        result.data.forEach((item, index) => {  
                            cenpoi = item.location;
                            if(type!=4){
                               
                                _this.templatitude=item.location.lat;
                                _this.templongitude=item.location.lng;
                                _this.newUrl.mappoint=item.location.lng+'|'+item.location.lat
                                _this.newUrl.mappointtx = item.location

                                _this.initMap(_this.newUrl)
                            } 
                        });
                        //设置中心点
                        if (cenpoi != null) {
                            _this.tMap.setCenter(cenpoi)
                            _this.tMap.setZoom(zoom)
                        } 
                    }); 
                
                }
            }, 
            listDataShow(value){
                console.log(value)
                if(value.Level==1){
                    instance.get('/Map/GetSecondHouse?id='+ value.Id).then((res) => { 
                        console.log(res)
                        this.handleClick(res.data.response)
                    }); 
                }else if(value.Level==2){//司法数据明细  
                    this.detailstc=true;
                    this.listzs=false;
                    this.sfInfo(value.Id)
                    }else if(value.Level==3){//新房数据明细  
                        this.detailstc=true;
                        this.listzs=false;
                        this.xfInfo(value.Id) 
                    }else if(value.Level==5){//土拍数据明细   
                        this.detailstc=true;
                        this.listzs=false;
                        this.tpInfo(value.Id)
                    } else if(value.Level==7){
                        this.detailstc=true;
                        this.listzs=false;
                        this.rentInfo(value.Id)
                    }

                    
            },
            gettablist(){
                this.listloading = true;
                this.datalistshow=false;
                var url = this.newUrl.pagerul+'&pageindex='+this.newUrl.page+'&pagesize='+this.newUrl.size;  
                instance.get(url).then((res) => {   
                        if(this.newUrl.type==1){ //二手 
                            this.datalisttitles=[
                                {
                                prop:'Name',
                                label:'名称',
                                },{
                                prop:'Community',
                                label:'小区',
                                },{
                                    prop:'HouseType',
                                label:'用途',
                                },{
                                    prop:'TradeDate',
                                label:'交易时间',
                                },{
                                 prop:'Area',
                                label:'面积(m²)',
                                },{
                                prop:'Price',
                                label:'单价(元/m²)',
                                },{
                               
                                prop:'Floor',
                                label:'楼层',
                                },
                            ]; 
                        }else if(this.newUrl.type==2){//法拍
                            this.datalisttitles=[
                                {
                                prop:'Name',
                                label:'地址',
                                },{
                                    prop:'HouseType',
                                label:'用途',
                                },{
                                    prop:'TradeDate',
                                label:'交易时间', 
                                },{
                                prop:'Area',
                                label:'面积(m²)',
                                },{
                                prop:'Price',
                                label:'成交单价(元/m²)',
                                } 
                            ]; 
                        } 
                        else if(this.newUrl.type==3){//新房
                            this.datalisttitles=[
                                {
                                prop:'Name',
                                label:'名称',
                                },{ 
                                prop:'HouseType',
                                label:'用途',
                            },{ 
                                prop:'TradeDate',
                                label:'预售时间',
                                },{ 
                                prop:'Area',
                                label:'面积',
                                },{ 
                                prop:'SPrice',
                                label:'单价',
                                },
                            ]; 
                        } 
                        else if(this.newUrl.type==5){//土拍
                            this.datalisttitles=[
                                {
                                prop:'Name',
                                label:'地址',
                            },{ 
                                prop:'HouseType',
                                label:'用途',
                            },{ 
                                prop:'TradeDate',
                                label:'交易时间',
                                },{ 
                                prop:'Area',
                                label:'面积(m²)',
                                },{ 
                                prop:'TotalPrices',
                                label:'总价(万元/亩)',
                            },{ 
                                prop:'Floor',
                                label:'容积率',
                                },{ 
                                prop:'Status',
                                label:'交易结果',
                                },{ 
                                prop:'IsRequire',
                                label:'是否配建',
                                },
                            ]; 
                        }else if(this.newUrl.type==7){ //租赁
                            this.datalisttitles=[
                                {
                                prop:'Name',
                                label:'名称',
                                },{
                                    prop:'Type',
                                label:'类型',
                                },{
                                    prop:'TradeDate',
                                label:'交易时间',
                                },{
                                 prop:'Area',
                                label:'面积(m²)',
                                },{
                                prop:'Price',
                                label:'单价(m²/月)',
                                },{
                                prop:'',
                                label:'总价(月)',
                                },{
                                prop:'AuctionResult',
                                label:'交易结果',
                                },
                            ]; 
                        }
                        this.datalist = res.data.response.data;
                        this.newUrl.pagenum = res.data.response.dataCount;
                        if( this.datalist !=null&&this.datalist.length>0){
                            this.datalistshow =true;
                            this.datalist.forEach(item => {
                                if(item.TradeDate!=null&&item.TradeDate.length>0){
                                    item.TradeDate = item.TradeDate.substr(0,10)
                                }
                                if(item.IsRequire){
                                    item.IsRequire = '是'
                                }else{
                                    item.IsRequire = '否'
                                }
                            });
                        }
                        this.listloading = false; 
                    });
            },
            currentTabList(v){ 
                this.newUrl.page = v;
                this.gettablist()
            }
        }
    }  
</script>
<style lang="less" scoped>
    
    .miaosubox{
        padding: 2rem;
        .lablevual{
            text-decoration:underline;
        }
    }
    .mianbox{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        .screenbox{
            box-sizing: border-box;
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            right: 1.5rem;
            z-index: 1999;
            .screen{
                background-color: #ffffff;
                box-shadow: 0 0 0.6rem #e0e0e0;
                border-radius:0.5rem ;
                .screenbox-one{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 6rem;
                    padding: 0 1.5rem;
                    .searchbox_left{
                        .leftbox{
                            display: flex;
                            align-items: center;
                            .biaoqian{
                                width: 60px;
                                color: #999999;
                                font-size: 1.2rem;
                                margin-right: 1rem;

                            } 
                            .locationbox{
                                width: 24rem; 
                             }
                            .left_one{
                                width: 26.8rem;
                            }
                            .left_three{
                                margin-left:2rem ;
                            }
                        }
                    }
                }
                .conditionbox{
                    .conditionbox_one{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                        .sourcebox{
                            width: 92%;
                            display: flex;
                            align-items: center;
                            list-style:none;
                            li{
                                list-style:none;
                                font-size: 1.2rem;
                                margin-right: 2.4rem;
                                color: #333333;
                                cursor: pointer;
                            }
                            .active_li{
                                padding: 0.4rem 0.8rem;
                                background-color: #fdf6ec;
                                border: 1px solid #faecd8;
                                border-radius: 0.4px;
                                color: #e6a23c;
                            }
                        }
                    }
                    .conditionbox_two{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                        .sourcebox{
                            width: 92%;
                            display: flex;
                            align-items: center;
                            list-style:none;
                            flex-wrap: wrap;
                            li{
                                list-style:none;
                                font-size: 1.2rem;
                                margin-right: 2.4rem;
                                color: #333333;
                                cursor: pointer;
                            }
                            .active_li{
                                padding: 0.4rem 0.8rem;
                                background-color: #fdf6ec;
                                border: 1px solid #faecd8;
                                border-radius: 0.4px;
                                color: #e6a23c;
                            }
                        }
                    }
                    .conditionbox_three{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                    }          
                    .timebox{
                            width: 92%;
                        }
                    .conditionbox_four{
                        border-top:1px dotted #E5E5E5;
                        padding: 1.5rem;
                        display: flex;
                        align-items: center;
                        .biaoqian{
                            width: 60px;
                            color: #999999;
                            font-size: 1.2rem;
                            margin-right: 1rem;
                        }
                        .shousuo{
                            width: 0rem;  
                        }
                        .Radiobox{
                            margin-left: 2rem;
                        }
                    }
                }
            }  
        }
        .mapview{
            width: 100%; 
            height: 100%;
            box-sizing: border-box;
        }
        .map{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
        .datalistbox{
            box-sizing: border-box;
            position: absolute;
            bottom: 1.5rem;
            left: 1.5rem;
            right: 1.5rem;
            z-index: 1999;
            width: 80rem;
            height: 48rem;
            background-color: #ffffff;
            border-radius: 1rem;
            .datalist_headbox{
                display: flex;
                justify-content: space-between;
                padding: 1.2rem;
                box-sizing: border-box;
                border-bottom: 0.1rem solid #E5E5E5;
                .head_left{
                    font-size: 1.6rem;
                }
                .head_right{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 2.4rem;
                    height: 2.4rem;
                    font-size:2.4rem ;
                    cursor: pointer;
                }
            }
            .datalist_bodybox{
                padding: 1.2rem;
                box-sizing: border-box;
                overflow:auto;
            }
        }
    }
</style>
